import {
  getTypesenseAuctionsByIDs as getTypesenseAuctionsByIDsLib,
  getTypesenseAuctions as getTypesenseAuctionsLib,
  getTypesenseHeadlines as getTypesenseHeadlinesLib,
  typesenseClient,
  useGetTypesenseAuctionsByIDs as useGetTypesenseAuctionsByIDsLib,
  useGetTypesenseHeadlines as useGetTypesenseHeadlinesLib,
} from '@nx/typesense'

import { config, configHeadline } from './getConfig'

const client = typesenseClient(config)
const clientHeadline = typesenseClient(configHeadline)

// wrapper methods for @nx lib methods for algolia search
export const getTypesenseAuctions = getTypesenseAuctionsLib(client)
/* istanbul ignore next */
export const getTypesenseAuctionsByIDs = (ids: string[] | number[]) =>
  getTypesenseAuctionsByIDsLib(client, ids)
export const getTypesenseHeadlines = getTypesenseHeadlinesLib(clientHeadline)

/* istanbul ignore next */
export const useGetTypesenseHeadlines = (
  args: Omit<Parameters<typeof useGetTypesenseHeadlinesLib>[0], 'client'>
) => useGetTypesenseHeadlinesLib({ client: clientHeadline, ...args })

/* istanbul ignore next */
export const useGetTypesenseAuctionsByIDs = (
  args: Omit<Parameters<typeof useGetTypesenseAuctionsByIDsLib>[0], 'client'>
) => useGetTypesenseAuctionsByIDsLib({ client, ...args })
