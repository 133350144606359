import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import getConfig from 'next/config'

import { GetTypesenseAuctionsParams } from '@nx/typesense'

import { getTypesenseAuctions } from '@web/helpers/api'

const { publicRuntimeConfig } = getConfig()

export type UseGetTypesenseAuctionsProps = GetTypesenseAuctionsParams & {
  startingPage: number
}

export function useGetTypesenseAuctions(props: UseGetTypesenseAuctionsProps) {
  const {
    startingPage,
    biddingStatus,
    auctionType,
    futureOnly,
    collection,
    ...otherParams
  } = props

  return useInfiniteQuery({
    queryKey: ['auctions', props],
    queryFn: ({ pageParam: page }) =>
      getTypesenseAuctions({
        collection,
        page,
        biddingStatus,
        auctionType,
        futureOnly,
        per_page: publicRuntimeConfig.AUCTIONS_PER_PAGE,
        ...otherParams,
      }),
    getNextPageParam: ({ page, nbPages }) =>
      page + 1 < nbPages ? page + 1 : page,
    staleTime: 0,
    initialPageParam: startingPage,
    placeholderData: keepPreviousData,
    retry: 3,
    retryDelay: 1000,
  })
}
